import React, { useEffect, createRef } from "react"
import Layout from "../components/layout"
import Markdown from "react-markdown"

export const query = graphql`
  query TermsQuery {
    strapiTerms {
      body
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`
export default function Terms({ data, location }) {
  const seo = { ...data.strapiTerms.seo, url: location.pathname }

  return (
    <Layout seo={seo}>
      <div className="container mx-auto px-4 mb-24 mt-12">
        <Markdown
          className="markdown green-bullets"
          source={data.strapiTerms.body}
          escapeHtml={false}
        />
      </div>
    </Layout>
  )
}
